






























































import { defineComponent, computed, useRouter, useContext, ref } from '@nuxtjs/composition-api';
import { SfButton, SfLoader } from '@storefront-ui/vue';
import FtButton from "~/components/customSFUI/vue/src/components/atoms/FtButton/FtButton.vue";
import { useUiState } from '~/composables';
import { useCart } from '~/modules/checkout/composables/useCart';
import { useCartView } from '~/modules/checkout/composables/useCartView';
import CouponCode from '~/modules/fortytwo/checkout/components/CouponCode.vue';
import getShippingMethodPrice from '~/helpers/checkout/getShippingMethodPrice';
import { useUiNotification } from '~/composables';

export default defineComponent({
    name: 'FortytwoCartTotal',
    components: {
        SfButton,
        SfLoader,
        FtButton,
        CouponCode,
    },
    props: {
        pageType: {
            type: String,
            default: '',
        },
    },
    setup(props) {
        const router = useRouter();
        const { app } = useContext();
        const cartView = useCartView();
        const { cart, load, getDiscountLabel, loading: loadingCart } = useCart();
        const discountLabel = computed(() => getDiscountLabel() ?? '');
        const selectedShippingMethod = computed(() => cartView.cartGetters.getSelectedShippingMethod(cart.value));
        const { toggleCartSidebar } = useUiState();
        const { send: sendNotification } = useUiNotification();

        const goToCart = async () => {
            await router.push(app.localeRoute({ name: 'cart' }));
        };

        const cartClick = async () => {
            await goToCart();
            if (props.pageType == 'cartSideBar') {
                toggleCartSidebar();
            }
        };

        const checkoutClick = async () => {
            // reload cart when click checkout button
            await load();
            const cartItemError = cart.value?.items?.filter((item) => item.errors != undefined || item.errors?.length > 0) ?? [];
            if (cartItemError.length > 0) {
                sendNotification({
                    id: Symbol('cart_error'),
                    message: cartItemError[0].product.name + ': ' + cartItemError[0].errors[0].message,
                    type: 'danger',
                    icon: '',
                    persist: false,
                    title: app.i18n.t('Error') as string,
                });
            } else {
                await cartView.goToCheckout();
                if (props.pageType == 'cartSideBar') {
                    toggleCartSidebar();
                }
            }
        };

        return {
            ...cartView,
            discountLabel,
            selectedShippingMethod,
            getShippingMethodPrice,
            cartClick,
            checkoutClick,
            loadingCart,
        };
    }
})
