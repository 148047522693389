












import { defineComponent,useContext,ref,onMounted } from "@nuxtjs/composition-api";
import { SfProperty } from '@storefront-ui/vue';

export default defineComponent({
  name: "CartCustomOption",
  components: {
  SfProperty,
  },
  props: ["product"],
  setup(props) {
    const cartItemInfor:any = ref({});

    const getSelectedValue = (cartItems) => {
      let selected = [];
      for(const cartitem of cartItems) {
        selected = [];
        for (const value of cartitem.values) {
          selected.push(value.label);
        }
        cartitem.label_value_selected = selected;
      }
    };

    cartItemInfor.value = props.product?.customizable_options ?? [];
    getSelectedValue(cartItemInfor.value);
    
    return {
    cartItemInfor
    };

  },
});
