import { defineStore } from 'pinia';

interface PaymentState {
  message: string;
}

export const usePaymentStore = defineStore('payment', {
  state: (): PaymentState => ({
    message: "",
  }),
  actions: {
    setMessage(message: string) {
      this.message = message;
    },
  },
});
