
















































import { defineComponent, computed, ref, onMounted, watch } from '@nuxtjs/composition-api';
import { SfInput, SfButton, SfIcon, SfLoader } from '@storefront-ui/vue';
import FtButton from "~/components/customSFUI/vue/src/components/atoms/FtButton/FtButton.vue";
import cartGetters from '~/modules/checkout/getters/cartGetters';
import { useCart } from '~/modules/checkout/composables/useCart';

export default defineComponent({
    name: 'FortytwoCouponCode',
    components: {
        SfInput,
        SfButton,
        SfIcon,
        SfLoader,
        FtButton,
    },
    setup() {
        const { cart, applyCoupon, removeCoupon, error, loading: isCartLoading} = useCart();
        const couponCodeUserInput = ref('');

        const couponCodeAppliedToCart = computed(() => cartGetters.getAppliedCoupon(cart.value)?.code);
        const isCouponCodeApplied = computed(() => couponCodeAppliedToCart.value !== undefined);
        const hasAnyError = computed(() => Object.values(error.value).some((value) => value !== null));
        const applyCouponMsg = computed<Error>(() => error.value.applyCoupon || error.value.removeCoupon || { message: '', name: 'apply-coupon' });
        const applyOrRemoveCoupon = async () => {
            if (isCouponCodeApplied.value) {
                await removeCoupon({});
                couponCodeUserInput.value = '';
            } else {
                if (couponCodeUserInput.value) {
                    await applyCoupon({ couponCode: couponCodeUserInput.value });
                }
            }
        };

        onMounted(() => {
            if(couponCodeAppliedToCart.value) {
                couponCodeUserInput.value = couponCodeAppliedToCart.value;
            }
        });

        watch(
        () => couponCodeAppliedToCart.value,
            async (newCouponCodeAppliedToCart) => {
                couponCodeUserInput.value = newCouponCodeAppliedToCart; 
            }   
        );

        return {
            couponCodeUserInput,
            isCouponCodeApplied,
            applyCouponMsg,
            applyOrRemoveCoupon,
            hasAnyError,
            isCartLoading,
        };
    }
})
