










import { defineComponent, ref } from "@nuxtjs/composition-api";
import { SfProperty } from '@storefront-ui/vue';

export default defineComponent({
  name: "FortytwoCartCustomAttribute",
  components: {
    SfProperty,
  },
  props: ["product"],
  setup(props) {
    const cartItemWarranty:any = ref('');
    cartItemWarranty.value = props?.product?.product?.product_warranty_label ?? '';
    return {
      cartItemWarranty,
    };

  },
});
