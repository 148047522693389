import { defineStore } from 'pinia';

interface ActiveCategoryState {
  activecategory: null,
}

export const useActiveCategoryStore = defineStore('activecategory', {
  state: (): ActiveCategoryState => ({
    activecategory: null,
  }),
});
