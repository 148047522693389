
















































































































































































































































import { defineComponent, computed, ref, onMounted, useRouter, useContext, watch, useFetch } from '@nuxtjs/composition-api';
import { SfPrice, SfButton, SfProperty, SfInput, SfNotification, SfLoader, SfBadge, SfHeading } from '@storefront-ui/vue';
import SkeletonLoader from "~/components/SkeletonLoader/index.vue";
import SvgImage from '~/components/General/SvgImage.vue';
import { merge } from 'lodash-es';
import { useUiState } from '~/composables';
import type { CartItemInterface } from "~/modules/GraphQL/types";
import { useCart } from '~/modules/checkout/composables/useCart';
import { useCartView } from '~/modules/checkout/composables/useCartView';
import { useActiveCategoryStore } from '~/modules/fortytwo/category/stores/activecategory';
import GA4AddRemoveCart from '~/modules/fortytwo/googleanalytics4/components/GA4AddRemoveCart.vue';
import useFtDelivery from '~/modules/fortytwo/delivery/composables/useFtDelivery';
import CartCustomOption from '~/modules/fortytwo/customoption/components/CartCustomOption.vue';
import CartCustomAttribute from '~/modules/fortytwo/cart/components/CartCustomAttribute.vue';
// import { setRecommendationContext } from '@/dy/recommendationContext';
// import { updateContext  } from '~/dy/recommendationContext2'
import CustomHead from '~/dy/customHead.vue';

export default defineComponent({
    name: 'FortytwoCartList',
    components: {
        SfPrice,
        SfButton,
        SfProperty,
        SfInput,
        SfNotification,
        SfLoader,
        SfBadge,
        SfHeading,
        SkeletonLoader,
        GA4AddRemoveCart,
        SvgImage,
        CartCustomOption,
        CartCustomAttribute,
        CustomHead,
    },
    props: {
        pageType: {
            type: String,
            default: '',
        },
    },
    methods: {
        searchProduct(products, item_uid){
        // return item_uid;
        const searchProduct = products.filter( product => product.uid == item_uid );
        return searchProduct;
        },
    },

    setup(props) {
        const { toggleCartSidebar } = useUiState();
        const cartView = useCartView();
        const getRowTotal = (product: CartItemInterface) =>
        cartView.cartGetters.getItemPrice(product).totalWithTax -
        cartView.cartGetters.getItemPrice(product).special;
        const dyHeadData = ref([]);
        const { app } = useContext();
        
        const { cart, load: loadCart, loading: loadingCart } = useCart();
        const { getCartDeliveryGroup, cartDeliveryGroup, loadingCartDeliveryGroup, getDisplayDate } = useFtDelivery();

        // get grand total group by delivery group
        const deliveryGroupsGrandTotal = computed(() => {
            const deliveryGroupsProductTotal = ref([]);
            if (!cartDeliveryGroup){
                return 0;
            }
            cartDeliveryGroup.value.delivery_groups.forEach(delivery_group => {
                let total = 0;
                delivery_group.items.forEach(item => {
                const searchProduct = cartView.products.value.filter( product => product.uid == item.item_id );
                total += getRowTotal(searchProduct[0]);
                })
                deliveryGroupsProductTotal.value[delivery_group.delivery_group_id] = total;
            });
            return deliveryGroupsProductTotal.value;
        });

        const deliveryCharges = (deliveryGroup) => {
            if (deliveryGroupsGrandTotal.value[deliveryGroup.delivery_group_id] < deliveryGroup.free_delivery_min_subtotal){
                return deliveryGroup.first_item_charge;
            }
            return 0;
        }

        const deliveryProgress = (deliveryGroup) => {
            if (deliveryGroupsGrandTotal.value[deliveryGroup.delivery_group_id] < deliveryGroup.free_delivery_min_subtotal) {
                return deliveryGroupsGrandTotal.value[deliveryGroup.delivery_group_id] / deliveryGroup.free_delivery_min_subtotal * 100;
            } 
            return 100;
        };

        const amtNeedForFreeDelivery = (deliveryGroup) => {
            if (deliveryGroupsGrandTotal.value[deliveryGroup.delivery_group_id] < deliveryGroup.free_delivery_min_subtotal){
                return deliveryGroup.free_delivery_min_subtotal - deliveryGroupsGrandTotal.value[deliveryGroup.delivery_group_id];
            }
            return 0;
        }

        onMounted(async () => {
            if (!cart.value?.id || !cart.value?.items) {
                await loadCart();
            }
            cart.value?.items?.forEach((item) =>{
                if(item.product.__typename == "SimpleProduct"){
                    dyHeadData.value.push(item.product.msbc_item_no);
                }else{
                  dyHeadData.value.push(item.product.sku);
                }
                
            });
            // updateContext({ type: 'CART', data: arrayCart.value })
            // app.$updateRecommendationContext({ type: 'CART', data: arrayCart.value });

            // to be call at cart.value watch
            // await Promise.all([getCartDeliveryGroup()]);
            // setRecommendationContext({type : 'CART', data: arrayCart.value});
        });

        // GA4
        const productInfo = ref({});
        // to avoid duplicated event send to the GA4, while in product page, user delete or add item using sidebar cart, it will tigger 2 time event call due to sidebar cart and product page cart using the same component in own page and binding the same things which is cart quantity.
        const triggerAddRemoveCartTrack = ref(false);
        // at category page, it will store the category title and product page able to use the category title and send to GA4.
        const { activecategory } = useActiveCategoryStore();
        // for delete all item used..
        const deleteAllFlag = ref(false);
        const fetchCustomProductInfoGa4 = async (product_info,deleteAll) => {
            triggerAddRemoveCartTrack.value = true;
            deleteAllFlag.value = deleteAll;
            productInfo.value = product_info;
        };
 
        const validateNumberOnly = (event) => {
            const keyCode = event.keyCode;
            const excludedKeys = [8, 37, 39, 46];
            if (!((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105) || (excludedKeys.includes(keyCode)))) {
                event.preventDefault();
            }
        };
        const validateMinNumber = (event) => {
            if(event.target.value < 1) {
                event.target.value = 1;
            }
            return event.target.value
        };

        const { localePath } = useContext();
        const router = useRouter();
        const handleHomeClick = async () => {
            await router.push(localePath('/'));
            if (props.pageType == 'cartSideBar') {
                toggleCartSidebar();
            }
        };

        const goToProductPage = async (product) => {
            await router.push(localePath(cartView.getProductPath(product)));
            if (props.pageType == 'cartSideBar') {
                toggleCartSidebar();
            }
        };

        const removeItemClick = async (itemRemove, emptyCart: boolean) => {
            emptyCart ? await cartView.removeAllItemAndSendNotification() : await cartView.removeItemAndSendNotification(itemRemove);
            await getCartDeliveryGroup(); 
            if(!emptyCart) {
                fetchCustomProductInfoGa4(itemRemove,true);
            }
        };

        const editItemClick = async(product, event) => {
            await validateMinNumber(event);
            await cartView.updateItemQtyAndSendNotification(product, event.target.value);
            await getCartDeliveryGroup(); 
            fetchCustomProductInfoGa4(product,false);
        };
        
        // When user login at cart page then the cart id will updated, will need run the getCartDeliveryGroup function again
        watch(
        () => cart.value?.id,
            async () => {
                await Promise.all([getCartDeliveryGroup()]);
            },
            {immediate: true}   
        );

        return {
            ...cartView,
            getRowTotal,
            getCartDeliveryGroup,
            cartDeliveryGroup,
            loadingCartDeliveryGroup,
            getDisplayDate,
            deliveryGroupsGrandTotal,
            deliveryCharges,
            deliveryProgress,
            amtNeedForFreeDelivery,
            fetchCustomProductInfoGa4,
            activecategory,
            productInfo,
            cart,
            loadingCart,
            triggerAddRemoveCartTrack,
            deleteAllFlag,
            validateNumberOnly,
            handleHomeClick,
            removeItemClick,
            editItemClick,
            goToProductPage,
            dyHeadData
        };
    },
})
