



























import { SfHeading } from '@storefront-ui/vue';
import {
  defineComponent,
  ref,
  useRoute,
  useContext,
  onMounted, 
  computed
} from '@nuxtjs/composition-api';
import { useCartView } from '~/modules/checkout/composables/useCartView';
import { useUiNotification } from '~/composables';
import { useUser } from '~/modules/customer/composables/useUser';
import CartList from '~/modules/fortytwo/cart/components/CartList.vue';
import CartTotal from '~/modules/fortytwo/cart/components/CartTotal.vue';
import { getMetaInfo } from '~/helpers/getMetaInfo';
import apichoice from "~/modules/fortytwo/dynamicyield/helper/dynamicyield";
import DYDataHandler from "~/components/FortyTwo/homepage/DYRecommendation/DYDataHandler.vue";
import { usePaymentStore } from '~/modules/fortytwo/payment/stores/payment';
import CartAlertMsg from '~/modules/checkout/components/CartAlertMsg.vue';
import CustomHead from '~/dy/customHead.vue';
import { useCustomerStore } from '~/modules/customer/stores/customer';

export default defineComponent({
  name: 'CartPage',
  components: {
    SfHeading,
    CartList,
    CartTotal,
    DYDataHandler,
    CartAlertMsg,
    CustomHead,
  },
  head() {  
    const page = {
      title: 'Shopping Cart',
    };
    return getMetaInfo(page);
  },
  setup() {
    const route = useRoute();
    const cartView = useCartView();
    const { isAuthenticated } = useUser();
    const { message } = usePaymentStore();
    const { app: { i18n } } = useContext();
    const customerStore = useCustomerStore();
    const { send: sendNotification } = useUiNotification();
    const { apidycall, loadingdy, dyresultdata } = apichoice();
    const DYProduct = ref();
    const dyHeadData = ref([]);
    const cartItemlist = ref([]);
    const errormessage:any = ref('');
    if(route?.value?.query?.error){
      errormessage.value = route?.value?.query?.error;
    }
    // alert box prompt the message via cart parameter "error". 
    const onError = async (message) => {
        sendNotification({
          id: Symbol('cart_notification'),
          message: message,
          type: 'danger',
          icon: '',
          persist: false,
          title: 'Cart Notification',
        });
    };

    const fetchDYData = async () => {
        const cartItemObj = cartView.products.value;
        cartItemObj.forEach(item => {
          if(item.product.__typename == "SimpleProduct"){
            cartItemlist.value.push(item.product.msbc_item_no);
          }else{
            cartItemlist.value.push(item.product.sku);
          }
        })
        const selectType = ["[Product] Cart Recommendation"];
        const productsku = cartItemlist.value;
        const currentURL = window.location.href;
        await apidycall(selectType,"CART","Choose",productsku,[],currentURL);
        DYProduct.value = { variationsId : dyresultdata.value[0].variationsId, data : dyresultdata.value[0].data, decisionId : dyresultdata.value[0].decisionId, title:dyresultdata.value[0].title, itemsku:dyresultdata.value[0].itemsku};
      }

    onMounted(async () => {
      if(process.client){
        fetchDYData();
      }
      if(route?.value?.query?.login){
        if(!isAuthenticated.value){
          await customerStore.setTriggerLoginModal(true);
        }
      }
      // if(route?.value?.query?.error){
      //     await onError(i18n.t(`${route.value.query.error}`));
      // }
    });

    return {
      ...cartView,
      DYProduct,
      message:errormessage,
      dyHeadData,
    };
  },
});
