<template><div id="GA4AddRemoveCart"></div></template>
<script>
import { onMounted } from "@nuxtjs/composition-api";
import { useUser } from '~/modules/customer/composables/useUser';
import { sha256 } from 'js-sha256';

export default {
  name: "GA4AddRemoveCart",
  props: {
    product: {
    type: Object,
    required: true,
    },
    cart: {
    type: Object,
    },
    componentFrom: {
    type: String,
    },
    triggerAddRemoveCartTrack: {
    type: Boolean,
    },
    cartitem: {
    type: Object,
    },
    deleteAllFlag: {
    type: Boolean,
    },
    activeCategory:{
    type: String,
    },
    qty:{
    type: Number,
    },
  },
  methods: {
    trackProductPage(eventAction) {
      if(this?.user?.email){
        const userId = sha256.hex(this.user.email);
        this.$gtag.event("userIdSet", {
          'event_label': "userIdSet",
          'currency': this.product.price_range.maximum_price.final_price.currency, 
          'items': [
            {
              item_id: this.product.sku,
              item_name: this.product.name,
              price: this.product.price_range.maximum_price.final_price.value,
              item_category: this.product?.product?.product_category ?? "",
              item_brand: this.product?.product?.product_brand_label ?? "",
              item_list_name: 'Category - '+this?.activeCategory ?? this.product?.product?.product_category,
              quantity: this.qty,
            }
          ],
          'user_id': userId
        });
      }

      this.$gtag.event(eventAction, {
        'event_label': eventAction,
        'currency': this.product.price_range.maximum_price.final_price.currency, 
        'items': [
          {
            item_id: this.product.sku,
            item_name: this.product.name,
            price: this.product.price_range.maximum_price.final_price.value,
            item_category: this.product?.product?.product_category ?? "",
            item_brand: this.product?.product?.product_brand_label ?? "",
            item_list_name: 'Category - '+this?.activeCategory ?? this.product?.product?.product_category,
            quantity: this.qty,
          }
        ]
      });
    },
    trackCatePageProduct() {

      if(this?.user?.email){
        this.$gtag.event("userIdSet", {
          'event_label': "userIdSet",
          'currency': this.product.cateProductDetails.price_range.maximum_price.final_price.currency, 
          'items': [
            {
              item_id: this.product.cateProductDetails.sku,
              item_name: this.product.cateProductDetails.name,
              price: this.product.cateProductDetails.price_range.maximum_price.final_price.value,
              item_category: this?.product?.cateProductDetails?.product_category ?? "",
              item_brand: this?.product?.cateProductDetails?.product_brand_label ?? "",
              item_list_name: 'Category - '+this.activeCategory ?? this?.product?.cateProductDetails?.product_category,
              quantity: 1,
            }
          ],
          'user_id': this?.user?.email
        });
      }

      this.$gtag.event("add_to_cart", {
        'event_label': "add_to_cart",
        'currency': this.product.cateProductDetails.price_range.maximum_price.final_price.currency, 
        'items': [
          {
            item_id: this.product.cateProductDetails.sku,
            item_name: this.product.cateProductDetails.name,
            price: this.product.cateProductDetails.price_range.maximum_price.final_price.value,
            item_category: this?.product?.cateProductDetails?.product_category ?? "",
            item_brand: this?.product?.cateProductDetails?.product_brand_label ?? "",
            item_list_name: 'Category - '+this.activeCategory ?? this?.product?.cateProductDetails?.product_category,
            quantity: 1,
          }
        ]
      });
    },
    trackCart(eventAction,quantity) {
      
      if(this?.user?.email){
        this.$gtag.event("userIdSet", {
          'event_label': "userIdSet",
          'currency': this.product?.product?.price_range?.maximum_price?.final_price?.currency ?? "", 
          'items': [
            {
              item_id: this.product?.product?.sku ?? "",
              item_name: this.product?.product?.name ?? "",
              price: this.product?.product?.price_range?.maximum_price?.final_price?.value,
              item_category: this.product?.product?.product_category ?? "",
              item_brand: this.product?.product?.product_brand_label ?? "",
              item_list_name: 'Category - '+this?.activeCategory ?? this.product?.product?.product_category,
              quantity: quantity,
            }
          ],
          'user_id': this?.user?.email
        });
      }

      this.$gtag.event(eventAction, {
        'event_label': eventAction,
        'currency': this.product?.product?.price_range?.maximum_price?.final_price?.currency ?? "", 
        'items': [
          {
            item_id: this.product?.product?.sku ?? "",
            item_name: this.product?.product?.name ?? "",
            price: this.product?.product?.price_range?.maximum_price?.final_price?.value ?? "",
            item_category: this.product?.product?.product_category ?? "",
            item_brand: this.product?.product?.product_brand_label ?? "",
            item_list_name: 'Category - '+this?.activeCategory ?? this.product?.product?.product_category,
            quantity: quantity,
          }
        ]
      });
    }
  },
  watch: {
    // cart quantity change will trigger to run add or remove cart event.
    cart(newValue, oldValue) {
      const oldCart = oldValue?.total_quantity ?? 0;
      const newCart = newValue?.total_quantity ?? 0;
      if(oldCart !== newCart){
      const action = oldCart > newCart ? "remove_from_cart": "add_to_cart";
        if(this.componentFrom === "productpage" && this.triggerAddRemoveCartTrack){
          if(this?.product?.sku){
            this.trackProductPage(action);
            this.$emit("triggerAddRemoveCartTrackFlag",false);
          }
        }else if(this.componentFrom === "cartsidebar" && !this.deleteAllFlag){
          if(this?.product?.product?.sku){
            this.trackCart(action,1);
          }
        }else if(this.componentFrom === "cartpage" && !this.deleteAllFlag && this.triggerAddRemoveCartTrack){
          if(this?.product?.product?.sku){
            this.trackCart(action,1);
            this.$emit("triggerAddRemoveCartTrackFlag",false);
          }
        }else if(this.componentFrom === "category" && this.triggerAddRemoveCartTrack){
          if(this?.product?.cateProductDetails?.sku){
            this.trackCatePageProduct();
            this.$emit("triggerAddRemoveCartTrackFlag",false);
          }
        }
      }
    },
    // when using delete all at cart page or sidebar cart, only will trigger this.
    cartitem(newValue, oldValue) {
      if (this.componentFrom === "cartsidebar" && this.deleteAllFlag){
        if(this?.product?.product?.sku){
          this.trackCart("remove_from_cart", this?.product?.quantity ?? 1);
        }
      }else if (this.componentFrom === "cartpage" && this.deleteAllFlag && this.triggerAddRemoveCartTrack){
        if(this?.product?.product?.sku){
          this.trackCart("remove_from_cart", this?.product?.quantity ?? 1);
        }
      }
    }
  },
  setup() {
    const { user, load: loadUser } = useUser();
    onMounted(async () => {
      if (user.value === null) {
        await loadUser();
      }
    });
    return{
      user
    }
  }
};
</script>

  